import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { CreateNewFeed, NewFeedDetail } from '@core/models/interfaces/home-page';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewFeedService {
  private baseUrl = `${environment.API_URL}/crm/new-feeds`;

  constructor(private httpClient: HttpClient) {}

  getNewFeedHomePage(params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<NewFeedDetail>>(`${this.baseUrl}/home-page`, { params: { ...params } });
  }

  getNewFeedManagement(params: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<NewFeedDetail>>(`${this.baseUrl}/management`, { params: { ...params } });
  }

  createNewFeed(body: CreateNewFeed) {
    return this.httpClient.post<ResponseApi<NewFeedDetail>>(this.baseUrl, body);
  }

  deleteNewFeed(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateNewFeed(id: string, body: CreateNewFeed) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, body);
  }

  getDetailNewFeed(id: string) {
    return this.httpClient.get<ResponseApi<NewFeedDetail>>(`${this.baseUrl}/${id}`);
  }

  updateBookmark(id: string, isPin: boolean) {
    return this.httpClient.put<ResponseApi<NewFeedDetail>>(`${this.baseUrl}/${id}/pinned`, null, {
      params: { isPin }
    });
  }
}
