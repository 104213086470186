import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateLink, LinkDetail } from '@core/models/interfaces/admin-content/admin-content';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  private baseUrl = `${environment.API_URL}/crm/links`;

  constructor(private httpClient: HttpClient) {}

  getLinkManagement(params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<LinkDetail>>(`${this.baseUrl}/management`, { params: { ...params } });
  }

  createLink(body: CreateLink) {
    return this.httpClient.post<ResponseApi<LinkDetail>>(this.baseUrl, body);
  }

  deleteLink(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateLink(id: string, body: CreateLink) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, body);
  }

  getDetailLink(id: string) {
    return this.httpClient.get<ResponseApi<LinkDetail>>(`${this.baseUrl}/${id}`);
  }

  pinLink(id: string, isPin: boolean) {
    return this.httpClient.put<ResponseApi<LinkDetail>>(`${this.baseUrl}/${id}/pinned`, null, {
      params: { isPin }
    });
  }
}
